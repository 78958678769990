// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "~src/assets/css/styles.scss";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$custom-dashboard-ui-primary: mat.define-palette(mat.$indigo-palette);
$custom-dashboard-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$custom-dashboard-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$custom-dashboard-ui-theme: mat.define-light-theme((color: (primary: $custom-dashboard-ui-primary,
        accent: $custom-dashboard-ui-accent,
        warn: $custom-dashboard-ui-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($custom-dashboard-ui-theme);

/* You can add global styles to this file, and also import other style files */


@font-face {
  font-family: 'Lato';
  src: url("assets/font/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Lato-Bold';
  src: url("assets/font/Lato-Bold.ttf") format("truetype");
}
@import '../node_modules/codemirror/lib/codemirror.css';
@import '../node_modules/codemirror/theme/material.css';

@import '../node_modules/codemirror/lib/codemirror.css';
@import '../node_modules/codemirror/theme/material.css';


html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

body::-webkit-scrollbar,
.mat-select-panel::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  transition: height 0.15s ease-out;
}

body::-webkit-scrollbar-track,
.mat-select-panel::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb,
.mat-select-panel::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  opacity: 0.1;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

body:hover::-webkit-scrollbar,
.mat-select-panel:hover::-webkit-scrollbar {
  height: 0.6em;
  transition: height 0.25s ease-in;
}

body:hover::-webkit-scrollbar-thumb,
.mat-select-panel:hover::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.3);
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}

body::-webkit-scrollbar-thumb:hover,
.mat-select-panel::-webkit-scrollbar-thumb:hover {
  opacity: 1;
  background: rgba(0, 0, 0, 0.55);
}

.externalBuilderModal,
.chartBuilderModalConfig {
  .mat-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }
}

@media (max-width: 1278px) {
  .chartBuilderModalConfig, .popupModalConfig, .externalBuilderModal {
    height: 620px !important;
    width: 99%;
    max-width: 99vw;
  }
}

/**responsive media queries**/
/* For screen width between 1278px and 1500px */
@media (min-width: 1278px) and (max-width: 1500px) {
  .chartBuilderModalConfig, .popupModalConfig, .externalBuilderModal {
    height: 620px !important;
    width: 91%;
    max-width: 91vw;
  }
}

/* For screen width between 1501px and 1650px */
@media (min-width: 1501px) and (max-width: 1650px) {
  .chartBuilderModalConfig, .popupModalConfig, .externalBuilderModal {
    height: 620px !important;
    width: 76%;
    max-width: 81vw;
  }
}

/* For screen width greater than 1650px */
@media (min-width: 1651px) {
  .chartBuilderModalConfig, .popupModalConfig, .externalBuilderModal {
    height: 620px !important;
    width: 65%;
    max-width: 81vw;
  }
}

.popupModalConfig {
  .mat-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }
}

html *,body * {
  outline: none
}

.no-input {
  .mat-input-element {
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    height: 20px !important;
    width: 100% !important;
    padding-right: 20px !important;
    padding-bottom: 0px !important;
    color: var(--strong-text) !important;
  }

  .mat-form-field-suffix {
    display: block !important;
  }

  &:hover,
  &:focus {
    .mat-input-element {
      border-bottom: 1px solid var(--lighter-grey) !important;
    }

    .mat-form-field-suffix {
      display: block;
      margin-left: 8px;
      position: relative;
      top: -1px;
    }
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-select-value {
    color: var(--primary) !important;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0px !important;
  }

  .mat-form-field-infix {
    border-top: 0px !important;
  }

  .mat-select-arrow-wrapper {
    position: relative !important;
    top: 0px !important;
  }

  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent {
    display: none !important;
  }
}

.btn-icon {
  border: 0px;
  background: transparent;
  color: var(--primary);
  opacity: 0.5;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.sharing-options.mat-menu-panel {
  right: 90px;
  top: 4px;
  min-width: 0px !important;
  max-width: none !important;

  .configuration-radio-group.mat-radio-group {
    .mat-radio-button {
      .mat-radio-label {
        font-size: 12px !important;
        padding: 2px 0px;

        .mat-radio-container {
          height: 12px;
          width: 12px;

          .mat-radio-outer-circle {
            height: 12px;
            width: 12px;
            top: 1px;
            border-color: var(--primary);
          }

          .mat-radio-inner-circle {
            height: 12px;
            top: 1px;
            width: 12px;
            background-color: var(--primary);
          }

          .mat-radio-ripple {
            display: none !important;
          }
        }
      }

      .mat-radio-button .mat-radio-ripple {
        position: absolute;
        left: calc(50% - 10px);
        top: calc(50% - 11px);
        height: 20px;
        width: 20px;
        z-index: 1;
        pointer-events: none;
      }

    }
  }

  .mat-menu-content:not(:empty) {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

/***cta styles***/
.button-cta {
  color: var(--primary);
  cursor: pointer;
  font-size: 12px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-align: center;

  &:hover {
    background-color: rgba(var(--primary-rgb), 10%);
  }
}

.chart-name-tooltip {
  background: var(--menu) !important;
  color: var(--white-color) !important;
  white-space: nowrap;
}

.chart-parameter-count-tooltip {
  font-size: 12px !important;
  font-weight: 400;
  position: relative;
  top: 12px;
}

.gauge-condition-count-tooltip {
  font-size: 12px !important;
  font-weight: 400;
  position: relative;
  top: 5px;
  right: 85px;
}

.md-drppicker {
  padding: 20px !important;
  font-family: var(--font-family) !important;
  font-size: 1em !important;
  max-width: 640px;

  .table-condensed {
    border-spacing: 0px;
  }

  th.month {
    text-transform: uppercase;
    color: var(--text);
    font-size: 14px;
  }

  &.double {
    .calendar {
      max-width: 295px !important;
      bottom: 5px;
      position: relative;

      th.available.prev,
      th.available.next {
        background: none;
        position: relative;
        vertical-align: middle;
        line-height: 30px;

        &::before {
          font-family: "Font Awesome 5 Free";
          content: "\f104";
          position: absolute;
          color: var(--primary);
          opacity: 0.5;
          vertical-align: middle;
          top: 50%;
          transform: translateY(-50%);
          height: 100%;
          font-size: 1.3em;
          text-align: center;
        }

        &:hover:before {
          opacity: 1;
        }
      }

      &.left {
        border-right: 1px solid var(--line);
      }

      td,
      th {
        border-radius: 0px !important;
        min-width: 40px;
        height: 30px;
        font-size: 14px;
        font-weight: 700;
        font-family: 'Lato';
      }

      td {
        span {
          padding: 0px;
          font-size: 12px !important;
          font-weight: 700 !important;
          font-family: "Lato";
        }

        &.available:not(.active) {
          &:hover {
            background-color: rgba(var(--primary-rgb), 0.2) !important;
          }
        }

        &::hover {
          border-radius: 0px !important;
        }

        &.in-range {
          background-color: rgba(var(--primary-rgb), 0.1) !important;
          border-radius: 0px !important;
          color: var(--text) !important;
        }

        &.active {
          background-color: var(--primary) !important;
          border-radius: 0px !important;
          color: var(--white-color) !important;
        }
      }

      th {
        span {
          padding: 0px;
          font-size: 12px !important;
          font-weight: 700 !important;
          color: var(--light-grey) !important;
        }

        &.available:not(.active):not(.prev):not(.next) {
          &:hover {
            background-color: rgba(var(--primary-rgb), 0.2) !important;
          }
        }

        &::hover {
          border-radius: 0px !important;
        }
      }
    }
  }

  .start-date,
  .end-date,
  .in-range {
    color: var(--white-color) !important;

    span {
      opacity: 1 !important;
    }
  }

  .btn {
    color: var(--primary) !important;
    box-shadow: none !important;
    background: transparent !important;
    margin: 0;
    min-width: auto;
    line-height: 25px !important;
  }

  .btn.btn-default::after {
    content: "|";
    color: var(--lighter-grey);
    font-size: 19px;
    line-height: 0px;
    margin-left: 13px;
    position: relative;
    top: -1px;
  }
}

.chart-config-icons-tooltip {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14px;
  position: relative;
  padding: 10px !important;
  top: 12px;
}

.chart-config-eye-icon-tooltip {
  font-size: 12px !important;
  font-weight: 400;
  line-height: 14px;
  position: relative;
  padding: 10px !important;
  top: 8px;
}

.chart-custom-select-panel {
  &.mat-select-panel {
    position: relative;
    top: 22px;
    left: 17px;
    min-width: calc(90% + 28px) !important;
    max-height: 180px;
    padding: 6px 0px;

    .mat-option {
      height: 28px;
      padding-left: 8px !important;

      .mat-option-text {
        font-size: 14px;
        font-style: 'lato';
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        height: 28px;
        color: var(--header-text);
      }
    }

    .mat-option:hover {
      background-color: var(--hover-grey) !important;
    }
  }
    .selected-option {
      background: rgba(var(--primary-rgb), 0.1) !important;
    }

  &.mat-autocomplete-panel {
    min-width: calc(90% + 13px) !important;
    max-height: 180px;
    padding: 6px 0px;

    .mat-option {
      height: 28px;
      padding-left: 8px !important;

      .mat-option-text {
        font-size: 14px;
        font-style: 'lato';
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        height: 28px;
        color: var(--header-text);
      }
    }

    .mat-option:hover {
      background-color: var(--hover-grey) !important;
    }
  }
}


.blockly-language-custom-select-panel {
  &.mat-select-panel {
    position: relative;
    top: 22px;
    left: 15px;
    min-width: calc(90% + 20px) !important;
    max-height: 180px;
    padding: 6px 0px;
    overflow: hidden;

    .mat-option {
      height: 28px;
      padding-left: 8px !important;

      .mat-option-text {
        font-size: 14px;
        font-style: 'lato';
        font-weight: 400;
        line-height: 16px;
        display: flex;
        align-items: center;
        height: 28px;
        color: var(--header-text);
      }
    }

    .mat-option:hover {
      background-color: var(--hover-grey) !important;
    }
  }
}

.blockly-language-custom-select-panel.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple),
.chart-custom-select-panel.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(var(--primary-rgb), 0.1);
}

.chart-custom-select-panel-config {
  &.mat-select-panel {
      position: relative;
      top: 22px;
      left: 40px;
      min-width: calc(94% + 28px) !important;
      max-height: 180px;
      padding: 6px 0px;
      
      .mat-option {
        height: 28px;
        padding-left: 8px !important;

        .mat-option-text {
          font-size: 14px;
          font-style: 'lato';
          font-weight: 400;
          line-height: 16px;
          display: flex;
          align-items: center;
          height: 28px;
          color: var(--header-text);
        }
      }

      .mat-option:hover {
        background-color: var(--hover-grey) !important;
      }
  }
}

.chart-custom-select-panel-config.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(var(--primary-rgb), 0.1);
}

.chart-selection-value.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-bottom: 0px !important;
}

.blockly-language-selection-dropdown.mat-form-field-appearance-legacy .mat-form-field-infix,
.chart-selection-dropdown.mat-form-field-appearance-legacy .mat-form-field-infix {
  border-top: 6px solid transparent !important;
}

.chart-selection-dropdown-config.mat-form-field-appearance-legacy .mat-form-field-infix {
  border-top: 0px solid transparent !important;
}

.chart-custom-select-trigger .mat-form-field-underline {
  background-color: var(--lighter-grey);
}

.blockly-language-selection-dropdown .mat-select-arrow,
.chart-selection-dropdown .mat-select-arrow,
.chart-selection-dropdown-config .mat-select-arrow{
  box-sizing: border-box;
  height: 6px !important;
  width: 6px !important;
  border-left: none !important;
  border-right: 2px solid var(--primary) !important;
  border-top: none !important;
  border-style: solid;
  border-color: var(--primary);
  border-width: 0 2px 2px 0;
  transform: rotate(45deg) !important;
  -webkit-transform: rotate(45deg) !important;
}

.blockly-language-selection-dropdown .mat-form-field.mat-focused .mat-select-arrow,
.chart-selection-dropdown .mat-form-field.mat-focused .mat-select-arrow {
  transform: rotate(45deg) !important;
}
.chart-selection-dropdown-config .mat-form-field.mat-focused .mat-select-arrow {
  transform: rotate(45deg) !important;
}

.chart-selection-dropdown .mat-select[aria-activedescendant], .chart-selection-dropdown-config .mat-select[aria-activedescendant] {
  .mat-select-arrow {
    box-sizing: border-box;
    height: 6px !important;
    width: 6px !important;
    border-left: none !important;
    border-right: 2px solid var(--primary) !important;
    border-top: none !important;
    border-style: solid;
    border-color: var(--primary);
    border-width: 0 2px 2px 0;
    transform: rotate(-135deg) !important;
    -webkit-transform: rotate(-135deg) !important;
  }
}

.chart-selection-dropdown, .chart-selection-dropdown-config {
  .mat-form-field-underline {
    background-color: var(--mid-grey) !important;
  }
}

.blockly-language-selection-dropdown.mat-form-field.mat-focused .mat-form-field-ripple,
.chart-selection-dropdown.mat-form-field.mat-focused .mat-form-field-ripple {
  width: fit-content;
  background-color: var(--header-text) !important;
}

.chart-selection-dropdown-config.mat-form-field.mat-focused .mat-form-field-ripple {
  width: fit-content;
  background-color: var(--header-text) !important;
}

.chart-aggregation-panel.mat-menu-panel {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch;
  max-height: calc(100vh - 48px);
  border-radius: 4px;
  outline: 0;
  min-height: 35px;
  position: relative !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  min-width: 100px !important;
  right: 5%;
  padding: 6px 0px !important;

  .mat-menu-content {
    .mat-menu-item {
      .mat-menu-submenu-icon {
        display: none;
      }
    }
  }

  .mat-menu-submenu-icon {
    display: none;
  }

  .sub-menu-btn {
    padding: 0px 0px 0px 12px !important;
    color: var(--header-text);
    font-size: 14px !important;
    font-family: 'Lato';
    font-weight: 400;
    line-height: 16px;
    padding: 0px 12px !important;
    height: 28px !important;
  }
}

color-sketch .sketch-picker {
  box-shadow: none !important;
}

.parameter-name-tooltip,
.hierarchy-entity-name-tooltip {
  background: var(--strong-text) !important;
  opacity: 0.8;
  max-width: fit-content !important;
}

.export-dashboard-dialog {
  .mat-dialog-container {
    padding: 0px !important;
    overflow: hidden;
  }
}

.chartConfig-parameter-name-tooltip {
  position: relative;
  left: 180px;
  opacity: 0.8;
  max-width: fit-content !important;
}

.exportConfig-parameter-name-tooltip {
  opacity: 0.8;
  max-width: fit-content !important;
  position: relative;
  top: 16px;
}

.dashboard-parameter-name-tooltip {
  opacity: 0.8;
  max-width: fit-content !important;
  position: relative;
  top: 12px;
}

.widget-parameter-name-tooltip {
  position: relative;
  left: 0px;
  top: 12px;
  opacity: 0.8;
  max-width: fit-content !important;
}

.disabled-state {
  .mat-select-arrow {
    border-color: var(--disabled-state) !important;
    color: var(--disabled-state) !important;
  }
}

.hierarchy-checkbox {
  .mat-checkbox-inner-container {
    width: 14px;
    height: 14px;
    margin-right: 0px !important;
  }

  .mat-checkbox-frame {
    border-radius: 2px;
    border: 1px solid var(--primary) !important;
  }
}

.hierarchy-checkbox-disabled {
  .mat-checkbox-frame {
    border: 1px solid var(--disabled-state) !important;
  }
}
.search-charts .mat-form-field .mat-form-field-infix,
.search-dashboards .mat-form-field .mat-form-field-infix {
  border-top: 0px !important;
}

.mat-checkbox-disabled .mat-checkbox-inner-container {
  cursor: not-allowed !important;
}

.warning-border.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: var(--error-state) !important;
}

.condition-dropdown .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}

.div-bgcolor {
  position: relative;
}

.div-bgcolor::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 4px;
  background: transparent;
  pointer-events: none;
}